import React, { useState, useEffect } from "react";
import {
  IonItem,
  IonLabel,
  IonToggle,
  IonSelect,
  IonSelectOption,
  IonChip,
  IonIcon,
} from "@ionic/react";

import { close } from "ionicons/icons";

import "../app.css";

const ExcludedUsers = ({ setExcludedUsers, excludedUsers }) => {
  const [excludedUsersMode, setExcludedUsersMode] = useState(false);

  const [triggerFetchUsers, setTriggerFetchUsers] = useState(true);
  const [userData, setUserData] = useState([]);

  let fetch_getUsers = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.NODE_ENV === "production"
        ? `https://fb-backend.saint-online.de/admin/allusers/`
        : `http://localhost:3000/`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => setUserData(result))
      .catch((error) => console.log("error", error));

    setTriggerFetchUsers(false);
  };

  useEffect(() => {
    triggerFetchUsers && fetch_getUsers();
  }, [triggerFetchUsers]);

  useEffect(() => {
    console.log(userData);
  }, [userData]);

  const customAlertOptions = {
    cssClass: "custom-button-class",
  };

  return (
    <>
      <IonItem lines="none">
        {excludedUsersMode && userData && (
          <IonSelect
            multiple
            className="selectCss"
            interfaceOptions={customAlertOptions}
            value={excludedUsers}
            placeholder="Auswählen"
            onIonChange={(e) => {
              setExcludedUsers(e.detail.value);
            }}
          >
            {userData.map((e, ind) => (
              <IonSelectOption key={ind}>{e.email}</IonSelectOption>
            ))}
          </IonSelect>
        )}
        <IonLabel slot="end" style={{ flex: "none" }}>
          Limit User View
        </IonLabel>
        <IonToggle
          slot="end"
          checked={excludedUsersMode}
          onIonChange={(e) => {
            setExcludedUsersMode(e.detail.checked);
            if (!e.detail.checked) {
              setExcludedUsers(null);
            }
          }}
        />
      </IonItem>
      {excludedUsers && excludedUsers.length > 0 && (
        <IonItem lines="none">
          {excludedUsers.map((user) => (
            <IonChip>
              <IonLabel>{user}</IonLabel>
              <IonIcon
                onClick={() => {
                  setExcludedUsers(
                    excludedUsers.filter((email) => email !== user)
                  );
                }}
                icon={close}
              />
            </IonChip>
          ))}
        </IonItem>
      )}
    </>
  );
};

export default ExcludedUsers;
