import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonFabButton,
  IonIcon,
  IonRadioGroup,
  IonRadio,
  IonInput,
  IonRouterLink,
  IonSelect,
  IonSelectOption,
  IonListHeader,
  IonCol,
  IonChip,
} from "@ionic/react";
import React, { useCallback, useState, useEffect } from "react";
import EditSurvey from "../components/EditSurvey.js";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import { add } from "ionicons/icons";
import NewInput from "../components/NewInput";
import ExcludedUsers from "../components/ExcludedUsers";
import Cookies from "universal-cookie";
import QRCode from "qrcode.react";

import "../app.css";

const SurveyAdmin = ({ credentials, router }) => {
  const [newSurvey, setNewSurvey] = useState(false);
  const [currentSurveys, setCurrentSurveys] = useState(null);
  const [currentClient, setCurrentClient] = useState(null);
  const [clientList, setClientList] = useState(null);
  const [surveyList, setSurveyList] = useState(null);
  const [triggerGet, setTriggerGet] = useState(true);
  const [surveyListChecked, setSurveyListChecked] = useState({ _id: null });
  const [currentSurvey, setCurrentSurvey] = useState(null);
  const [surveyRenameMode, setSurveyRenameMode] = useState(false);
  const [newShortcutLink, setNewShortcutLink] = useState(false);
  const [newCustomLink, setNewCustomLink] = useState(false);

  const [text, setText] = useState();
  // const [showModal, setShowModal] = useState(false);
  const [questionOnPage, setQuestionOnPage] = useState(null);
  const [elementOnPage, setElementOnPage] = useState(null);
  const [answerOnPage, setAnswerOnPage] = useState(null);
  const [shortCutMode, setShortCutMode] = useState(null);
  const [excludedUsers, setExcludedUsers] = useState([]);
  const [editMode, setEditMode] = useState(false);

  const surveyHandler = async (trigger, surveyid, inputvalue) => {
    try {
      const initfetch = await fetch(
        process.env.NODE_ENV === "production"
          ? `https://fb-backend.saint-online.de/editsurvey/`
          : `http://localhost:3000/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: credentials.email,
            trigger,
            clientid: credentials.clientid,
            surveyid,
            ...(inputvalue && { input: inputvalue }),
          }),
        }
      );

      const data = await initfetch.json();

      console.log(data);
    } catch (err) {
      console.log(err);
    } finally {
      setSurveyRenameMode(false);
      setText(null);
      setSurveyListChecked({ _id: null });
      setTriggerGet(true);
    }
  };
  const customAlertOptions = {
    cssClass: "custom-button-class",
  };

  useEffect(() => {
    if (surveyListChecked && currentSurveys) {
      const tempArr = currentSurveys.filter(
        (e) => e._id === surveyListChecked._id
      );
      setCurrentSurvey(tempArr[0]);
    }
  }, [surveyListChecked, currentSurveys]);

  const compareWith = (o1, o2) => {
    return o1 && o2 ? o1.pageTitle === o2.pageTitle : o1 === o2;
  };

  const compareWith1 = (o1, o2) => {
    return o1 && o2 ? o1.questionTitle === o2.questionTitle : o1 === o2;
  };

  const getsurveys = useCallback(async () => {
    try {
      const cookies = new Cookies();
      const query = await fetch(
        process.env.NODE_ENV === "production"
          ? `https://fb-backend.saint-online.de/getsurvey/`
          : `http://localhost:3000/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            admincookie: cookies.get("admincookie"),
          }),
        }
      );

      const data = await query.json();

      if (data.privilages === 2) {
        setClientList(data.clients);
        setSurveyList(data.surveys);
        console.log(data.surveys);
      } else {
        setCurrentSurveys(data.current);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTriggerGet(false);
    }
  }, []);

  useEffect(() => {
    triggerGet && getsurveys();
  }, [triggerGet, getsurveys]);

  useEffect(() => {
    if (currentClient && credentials.privilages === 2) {
      const tempSurveys = surveyList;
      const res = tempSurveys.filter((e) => e.clientid === currentClient);

      setCurrentSurveys(res);
    }
  }, [currentClient, surveyList, credentials.privilages]);

  const shortCutHandler = async () => {
    // const surveyid = currentSurvey._id;
    const pageid = questionOnPage.pageId;
    // const pagetitle = questionOnPage.pageTitle;
    const questionid = elementOnPage.questionId;
    // const questiontype = elementOnPage.questionType;
    // const questiontitle = elementOnPage.questionTitle;
    // const questiontext = elementOnPage.questionText;
    const answer = answerOnPage;

    const prodorlocal =
      process.env.NODE_ENV === "production"
        ? "https://feedback.saint-online.de/"
        : "http://localhost:8101/";

    const link = `${prodorlocal}dynamic/${
      currentSurvey._id
    }?pagetitle=${questionOnPage.pageTitle.replace(/ /g, "+")}&type=${
      elementOnPage.questionType
    }&questiontitle=${elementOnPage.questionTitle.replace(
      / /g,
      "+"
    )}&elementid=${
      elementOnPage.questionId
    }&question=${elementOnPage.questionText.replace(
      / /g,
      "+"
    )}&response=${answerOnPage.replace(/ /g, "+")}&pageid=${
      questionOnPage.pageId
    }&flag=skip`;

    const url = new URL(link);

    try {
      const query = await fetch(
        process.env.NODE_ENV === "production"
          ? `https://fb-backend.saint-online.de/editsurvey/`
          : `http://localhost:3000/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            trigger: "addshortcut",
            surveyid: surveyListChecked._id,
            input: text,
            linkbuilder: url,
            userlistlimitedview: excludedUsers,
            limitedview: {
              active: true,
              pageid,
              questionid,
              answer,
              surveyid: surveyListChecked._id,
            },
          }),
        }
      );

      const data = await query.json();

      console.log(data);
    } catch (err) {
      console.log(err);
    } finally {
      setExcludedUsers([]);
      setShortCutMode(false);
      setText(null);
      setSurveyListChecked({ _id: null });
      setTriggerGet(true);
      setQuestionOnPage(null);
      setElementOnPage(null);
      setAnswerOnPage(null);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Survey Admin</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonToolbar className="ion-padding-start">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Admin Console</Typography>
            <Typography color="textPrimary">Survey Overview</Typography>
            {surveyListChecked && editMode && (
              <Typography color="textPrimary">
                Edit Survey: {surveyListChecked.name}
              </Typography>
            )}
          </Breadcrumbs>
        </IonToolbar>
        <IonGrid>
          <IonRow style={{ width: "100%" }}>
            <IonItem style={{ width: "100%" }} lines="none">
              <IonButton
                onClick={() => {
                  setEditMode(false);
                  setTriggerGet(true);
                  setText(null);
                  setSurveyListChecked({ _id: null });
                }}
                slot="end"
              >
                Zurück zur Übersicht
              </IonButton>
            </IonItem>
          </IonRow>
          {editMode ? (
            <EditSurvey
              credentials={
                credentials.privilages === 2
                  ? currentClient
                  : credentials.clientid
              }
              surveyListChecked={surveyListChecked}
            />
          ) : (
            <>
              {credentials.privilages === 2 && (
                <IonItem disabled={shortCutMode ? true : false}>
                  <IonSelect
                    className="selectCss"
                    interfaceOptions={customAlertOptions}
                    placeholder={"Please Choose Client"}
                    value={currentClient}
                    onIonChange={(e) => setCurrentClient(e.detail.value)}
                  >
                    {clientList &&
                      clientList.map((el) => (
                        <IonSelectOption key={el._id} value={el._id}>
                          {el.name}
                        </IonSelectOption>
                      ))}
                  </IonSelect>
                </IonItem>
              )}
              {currentSurveys && (
                <>
                  {" "}
                  <IonRow
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "100px",
                    }}
                    className="ion-padding"
                  >
                    <div style={{ paddingRight: "10px" }}>
                      <IonFabButton
                        disabled={shortCutMode ? true : false}
                        onClick={() => setNewSurvey(true)}
                      >
                        <IonIcon icon={add} />
                      </IonFabButton>
                    </div>

                    {newSurvey ? (
                      <IonItem>
                        <NewInput
                          credentials={
                            credentials.privilages === 2
                              ? currentClient
                              : credentials.clientid
                          }
                          trigger={"newsurvey"}
                          setNewInput={setNewSurvey}
                          setTriggerGet={setTriggerGet}
                          setSurveyListChecked={setSurveyListChecked}
                        />
                      </IonItem>
                    ) : (
                      <IonItem
                        lines="none"
                        disabled={shortCutMode ? true : false}
                      >
                        Umfrage erstellen
                      </IonItem>
                    )}
                  </IonRow>
                  {currentSurveys && (
                    <IonList>
                      <IonRadioGroup value={surveyListChecked._id}>
                        {currentSurveys.map((e1) => (
                          <>
                            <IonItem
                              disabled={
                                surveyRenameMode &&
                                surveyListChecked &&
                                surveyListChecked._id !== e1._id
                              }
                              button
                              onClick={() => {
                                setSurveyListChecked({
                                  _id: e1._id,
                                  name: e1.name,
                                });
                              }}
                              key={e1._id}
                            >
                              {surveyRenameMode &&
                              surveyListChecked &&
                              surveyListChecked._id === e1._id ? (
                                <IonInput
                                  value={text}
                                  placeholder="Enter New Survey Name"
                                  onIonChange={(e) => setText(e.detail.value)}
                                ></IonInput>
                              ) : (
                                <IonLabel>{e1.name}</IonLabel>
                              )}
                              <IonRadio slot="start" value={e1._id} />
                              {surveyRenameMode &&
                              surveyListChecked &&
                              surveyListChecked._id === e1._id ? null : (
                                <IonButton
                                  style={{ marginLeft: "4px" }}
                                  slot="end"
                                  disabled={
                                    (surveyListChecked &&
                                      surveyListChecked._id !== e1._id) ||
                                    shortCutMode
                                      ? true
                                      : false
                                  }
                                  onClick={() => setEditMode(true)}
                                >
                                  Ändern
                                </IonButton>
                              )}
                              {
                                  
                                        <IonItem>
            {/* <IonLabel style={{width: '150px'}}>Sprache</IonLabel> */}
            <IonButton   color={ e1.language && e1.language === 'deutsch' ? 'primary' : 'medium'}  onClick={() => surveyHandler(
                                          "setLanguage",
                                          e1._id,
                                          'deutsch'
                                        )}>deutsch</IonButton>
                                         <IonButton  color={ e1.language && e1.language === 'englisch' ? 'primary' : 'medium'} onClick={() => surveyHandler(
                                          "setLanguage",
                                          e1._id,
                                          'englisch'
                                        )}>englisch</IonButton>
            {/* <IonSelect name="language" value={e1.language}  onIonClick={(e) => surveyHandler(
                                          "setLanguage",
                                          e1._id,
                                          e.target.value
                                        )} interface="popover">
              <IonSelectOption value="deutsch">deutsch</IonSelectOption>
              <IonSelectOption value="englisch">englisch</IonSelectOption>

            </IonSelect> */}
          </IonItem>
                              }
                              {
                              
                                <IonButton
                                  style={{ marginLeft: "4px" }}
                                  slot="end"
                                  disabled={
                                    (surveyListChecked &&
                                      surveyListChecked._id !== e1._id) ||
                                    shortCutMode
                                      ? true
                                      : false
                                  }
                                  onClick={() =>
                                    !surveyRenameMode &&
                                    surveyListChecked &&
                                    surveyListChecked._id === e1._id
                                      ? setSurveyRenameMode(true)
                                      : surveyHandler(
                                          "renamesurvey",
                                          e1._id,
                                          text
                                        )
                                  }
                                >
                                  {surveyRenameMode &&
                                  surveyListChecked &&
                                  surveyListChecked._id === e1._id
                                    ? "Speichern"
                                    : "Umbenennen"}
                                </IonButton>
                              }
                              <IonButton
                                style={{ marginLeft: "4px" }}
                                slot="end"
                                disabled={
                                  (surveyListChecked &&
                                    surveyListChecked._id !== e1._id) ||
                                  shortCutMode
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  if (
                                    surveyRenameMode &&
                                    surveyListChecked &&
                                    surveyListChecked._id === e1._id
                                  ) {
                                    setSurveyRenameMode(false);
                                    setText(null);
                                  } else {
                                    surveyHandler("deletesurvey", e1._id);
                                  }
                                }}
                              >
                                {surveyRenameMode &&
                                surveyListChecked &&
                                surveyListChecked._id === e1._id
                                  ? "Abbrechen"
                                  : "Löschen"}
                              </IonButton>
                            </IonItem>
                            <IonItem>
                              Link zur Umfrage:{"  "}
                              <IonRouterLink
                                onClick={() => {
                                  window.open(
                                    process.env.NODE_ENV === "production"
                                      ? `https://feedback.saint-online.de/dynamic/${e1._id}`
                                      : `http://localhost:8101/dynamic/${e1._id}`
                                  );
                                }}
                                href={
                                  process.env.NODE_ENV === "production"
                                    ? `https://feedback.saint-online.de/dynamic/${e1._id}`
                                    : `http://localhost:8101/dynamic/${e1._id}`
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {"https://feedback.saint-online.de/dynamic/" +
                                  e1._id}
                              </IonRouterLink>
                              <IonItem
                                lines="none"
                                style={{
                                  marginBottom: "10px",
                                  marginTop: "10px",
                                }}
                                slot="end"
                              >
                                <QRCode
                                  value={`https://feedback.saint-online.de/dynamic/${e1._id}`}
                                />
                              </IonItem>
                            </IonItem>
                            {surveyListChecked._id === e1._id && (
                              <>
                                {e1.shortcutslinks && (
                                  <IonListHeader>
                                    <IonLabel>Link Shortcuts</IonLabel>
                                  </IonListHeader>
                                )}
                                {e1.shortcutslinks &&
                                  e1.shortcutslinks.map((elem) => (
                                    <>
                                      <IonItem
                                        style={{
                                          marginBottom: "5px",
                                        }}
                                        key={elem.shortcutid._id}
                                      >
                                        <IonList>
                                          <IonItem lines="none">
                                            Shortcut Name:{" "}
                                            {elem.shortcutid.title}
                                          </IonItem>
                                          {elem.shortcutid
                                            .userlistlimitedview &&
                                            credentials.privilages === 2 &&
                                            elem.shortcutid.userlistlimitedview
                                              .length > 0 && (
                                              <IonItem lines="none">
                                                Limited View:
                                                <IonList>
                                                  {elem.shortcutid.userlistlimitedview.map(
                                                    (user) => (
                                                      <IonChip>
                                                        <IonLabel>
                                                          {user}
                                                        </IonLabel>
                                                      </IonChip>
                                                    )
                                                  )}
                                                </IonList>
                                              </IonItem>
                                            )}
                                        </IonList>

                                        {elem.shortcutid.customslug ? (
                                          <>
                                            {newCustomLink !==
                                              elem.shortcutid._id && (
                                              <>
                                                <IonItem
                                                  lines="none"
                                                  slot="end"
                                                >
                                                  <IonRouterLink
                                                    onClick={() => {
                                                      window.open(
                                                        process.env.NODE_ENV ===
                                                          "production"
                                                          ? `https://feedback.saint-online.de/url/${elem.shortcutid.customslug}`
                                                          : `http://localhost:8101/url/${elem.shortcutid.customslug}`
                                                      );
                                                    }}
                                                    href={
                                                      process.env.NODE_ENV ===
                                                      "production"
                                                        ? `https://feedback.saint-online.de/url/${elem.shortcutid.customslug}`
                                                        : `http://localhost:8101/url/${elem.shortcutid.customslug}`
                                                    }
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >{`https://feedback.saint-online.de/url/${elem.shortcutid.customslug}`}</IonRouterLink>

                                                  <IonButton
                                                    onClick={() =>
                                                      setNewCustomLink(
                                                        elem.shortcutid._id
                                                      )
                                                    }
                                                    style={{
                                                      marginLeft: "10px",
                                                    }}
                                                  >
                                                    Edit Link
                                                  </IonButton>
                                                </IonItem>
                                              </>
                                            )}
                                            {newCustomLink ===
                                              elem.shortcutid._id && (
                                              <IonItem slot="end">
                                                <NewInput
                                                  credentials={
                                                    credentials.privilages === 2
                                                      ? currentClient
                                                      : credentials.clientid
                                                  }
                                                  trigger={"newshortcutlink"}
                                                  setNewInput={setNewCustomLink}
                                                  setTriggerGet={setTriggerGet}
                                                  currentElementId={
                                                    elem.shortcutid._id
                                                  }
                                                  currentSurveyId={
                                                    surveyListChecked._id
                                                  }
                                                  placeholdertext={
                                                    elem.shortcutid.customslug
                                                  }
                                                  inputlimitation={"nospace"}
                                                />
                                              </IonItem>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            <IonItem slot="end" lines="none">
                                              <div
                                                style={{ paddingRight: "5px" }}
                                              >
                                                <IonFabButton
                                                  onClick={() =>
                                                    setNewShortcutLink(
                                                      elem.shortcutid._id
                                                    )
                                                  }
                                                >
                                                  <IonIcon icon={add} />
                                                </IonFabButton>
                                              </div>
                                              <IonItem>
                                                {newShortcutLink ===
                                                elem.shortcutid._id ? (
                                                  <NewInput
                                                    credentials={
                                                      credentials.privilages ===
                                                      2
                                                        ? currentClient
                                                        : credentials.clientid
                                                    }
                                                    trigger={"newshortcutlink"}
                                                    setNewInput={
                                                      setNewShortcutLink
                                                    }
                                                    setTriggerGet={
                                                      setTriggerGet
                                                    }
                                                    currentElementId={
                                                      elem.shortcutid._id
                                                    }
                                                    currentSurveyId={
                                                      surveyListChecked._id
                                                    }
                                                    inputlimitation={"nospace"}
                                                  />
                                                ) : (
                                                  "Create Custom Link"
                                                )}
                                              </IonItem>
                                            </IonItem>
                                          </>
                                        )}

                                        {/* <IonButton
                                        style={{ marginLeft: "4px" }}
                                        onClick={() => {
                                          window.open(elem.link);
                                        }}
                                        href={elem.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Link To shortcut
                                      </IonButton> */}
                                        {/* <IonButton
                                        style={{ marginLeft: "4px" }}
                                        slot="end"
                                      >
                                        Download QR code
                                      </IonButton> */}

                                        <IonItem
                                          lines="none"
                                          style={{ marginLeft: "4px" }}
                                          slot="end"
                                        >
                                          <QRCode
                                            value={elem.shortcutid.link}
                                          />
                                        </IonItem>
                                      </IonItem>
                                    </>
                                  ))}

                                <IonToolbar>
                                  <IonButton
                                    onClick={() => {
                                      setShortCutMode((e) => {
                                        if (e) {
                                          setShortCutMode(false);
                                          setQuestionOnPage(null);
                                          setElementOnPage(null);
                                          setAnswerOnPage(null);
                                          setText(null);
                                          setExcludedUsers([]);
                                        } else {
                                          setShortCutMode(true);
                                        }
                                      });
                                    }}
                                    size="small"
                                    expand="full"
                                    fill="outline"
                                  >
                                    {"Click to add shortcut"}
                                  </IonButton>
                                  {shortCutMode && (
                                    <div style={{ minHeight: "200px" }}>
                                      <IonCol size="12">
                                        <IonItem>
                                          <IonInput
                                            value={text}
                                            placeholder="Enter Shortcut Name"
                                            onIonChange={(e) =>
                                              setText(e.detail.value)
                                            }
                                          ></IonInput>
                                        </IonItem>
                                      </IonCol>

                                      <>
                                        {text && text.length > 0 && (
                                          <>
                                            {credentials.privilages === 2 && (
                                              <ExcludedUsers
                                                excludedUsers={excludedUsers}
                                                setExcludedUsers={
                                                  setExcludedUsers
                                                }
                                              />
                                            )}
                                            <IonItem style={{ width: "100%" }}>
                                              <IonLabel>
                                                Auf welcher Seite befindet sich
                                                die Frage?
                                              </IonLabel>
                                              <IonSelect
                                                className="selectCss"
                                                interfaceOptions={
                                                  customAlertOptions
                                                }
                                                compareWith={compareWith}
                                                value={questionOnPage}
                                                placeholder="Auswählen"
                                                onIonChange={(e) => {
                                                  setQuestionOnPage(
                                                    e.detail.value
                                                  );
                                                  if (e.detail.value) {
                                                    setElementOnPage(null);
                                                    setAnswerOnPage(null);
                                                  }
                                                }}
                                              >
                                                {currentSurvey.pages.map(
                                                  (e, ind) => (
                                                    <IonSelectOption
                                                      key={ind}
                                                      value={{
                                                        pageTitle: e.title,
                                                        pageId: e._id,
                                                      }}
                                                    >
                                                      {e.title}
                                                    </IonSelectOption>
                                                  )
                                                )}
                                              </IonSelect>
                                            </IonItem>
                                            {questionOnPage !== null && (
                                              <IonCol size="12">
                                                <IonItem
                                                  style={{ width: "100%" }}
                                                >
                                                  {currentSurvey.pages
                                                    .filter(
                                                      (element) =>
                                                        element._id ===
                                                        questionOnPage.pageId
                                                    )[0]
                                                    .elements.filter(
                                                      (e) =>
                                                        e.type === "boolean" ||
                                                        e.type === "dropdown"
                                                    ).length ? (
                                                    <>
                                                      <IonLabel>
                                                        Bitte Frage auswählen
                                                      </IonLabel>
                                                      <IonSelect
                                                        className="selectCss"
                                                        interfaceOptions={
                                                          customAlertOptions
                                                        }
                                                        compareWith={
                                                          compareWith1
                                                        }
                                                        value={elementOnPage}
                                                        placeholder="Auswählen"
                                                        onIonChange={(e) => {
                                                          setElementOnPage(
                                                            e.detail.value
                                                          );
                                                          if (e.detail.value) {
                                                            setAnswerOnPage(
                                                              null
                                                            );
                                                          }
                                                        }}
                                                      >
                                                        {currentSurvey.pages
                                                          .filter(
                                                            (element) =>
                                                              element._id ===
                                                              questionOnPage.pageId
                                                          )[0]
                                                          .elements.filter(
                                                            (e) =>
                                                              e.type ===
                                                                "boolean" ||
                                                              e.type ===
                                                                "dropdown"
                                                          )
                                                          .map((e, ind) => (
                                                            <IonSelectOption
                                                              key={ind}
                                                              value={{
                                                                questionTitle:
                                                                  e.title,
                                                                questionId:
                                                                  e._id,
                                                                questionType:
                                                                  e.type,
                                                                questionText:
                                                                  e.question,
                                                              }}
                                                            >
                                                              {e.title}
                                                            </IonSelectOption>
                                                          ))}
                                                      </IonSelect>
                                                    </>
                                                  ) : (
                                                    <IonLabel>
                                                      Sorry. Das ist keine
                                                      berechtigte Frage
                                                    </IonLabel>
                                                  )}
                                                </IonItem>
                                              </IonCol>
                                            )}
                                            {elementOnPage !== null &&
                                              questionOnPage !== null && (
                                                <IonCol size="12">
                                                  <IonItem
                                                    style={{ width: "100%" }}
                                                  >
                                                    <IonLabel>
                                                      Bitte Antwort auswählen:
                                                    </IonLabel>
                                                    <IonSelect
                                                      className="selectCss"
                                                      interfaceOptions={
                                                        customAlertOptions
                                                      }
                                                      value={answerOnPage}
                                                      placeholder="Auswählen"
                                                      onIonChange={(e) =>
                                                        setAnswerOnPage(
                                                          e.detail.value
                                                        )
                                                      }
                                                    >
                                                      {currentSurvey.pages
                                                        .filter(
                                                          (element) =>
                                                            element._id ===
                                                            questionOnPage.pageId
                                                        )[0]
                                                        .elements.filter(
                                                          (elem) =>
                                                            elem._id ===
                                                            elementOnPage.questionId
                                                        )[0].type ===
                                                      "boolean" ? (
                                                        <>
                                                          <IonSelectOption>
                                                            {"ja"}
                                                          </IonSelectOption>{" "}
                                                          <IonSelectOption>
                                                            {"nein"}
                                                          </IonSelectOption>{" "}
                                                        </>
                                                      ) : currentSurvey.pages
                                                          .filter(
                                                            (element) =>
                                                              element._id ===
                                                              questionOnPage.pageId
                                                          )[0]
                                                          .elements.filter(
                                                            (elem) =>
                                                              elem._id ===
                                                              elementOnPage.questionId
                                                          )[0].type ===
                                                        "dropdown" ? (
                                                        <>
                                                          {currentSurvey.pages
                                                            .filter(
                                                              (element) =>
                                                                element._id ===
                                                                questionOnPage.pageId
                                                            )[0]
                                                            .elements.filter(
                                                              (elem) =>
                                                                elem._id ===
                                                                elementOnPage.questionId
                                                            )[0]
                                                            .choices.map(
                                                              (e, ind) => (
                                                                <IonSelectOption
                                                                  key={ind}
                                                                  value={e}
                                                                >
                                                                  {e}
                                                                </IonSelectOption>
                                                              )
                                                            )}
                                                        </>
                                                      ) : null}
                                                    </IonSelect>
                                                  </IonItem>
                                                </IonCol>
                                              )}
                                          </>
                                        )}
                                        <IonItem>
                                          <IonButton
                                            onClick={() => {
                                              setShortCutMode(false);
                                              setQuestionOnPage(null);
                                              setElementOnPage(null);
                                              setAnswerOnPage(null);
                                              setText(null);
                                              setExcludedUsers([]);
                                            }}
                                            style={{ marginLeft: "4px" }}
                                            slot="end"
                                          >
                                            Cancel
                                          </IonButton>
                                          <IonButton
                                            onClick={() => shortCutHandler()}
                                            disabled={
                                              answerOnPage ? false : true
                                            }
                                            style={{ marginLeft: "4px" }}
                                            slot="end"
                                          >
                                            Create Shortcut
                                          </IonButton>
                                        </IonItem>
                                      </>
                                    </div>
                                  )}
                                </IonToolbar>
                              </>
                            )}
                          </>
                        ))}
                      </IonRadioGroup>
                    </IonList>
                  )}
                </>
              )}
            </>
          )}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default SurveyAdmin;
