import {
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
} from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import '../pages/MenuAdmin.css';

// moment.locale("en-GB");
// const localizer = momentLocalizer(moment);

const MenuAdmin = ({ credentials }) => {
  let dt = DateTime.local();

  const [dte, setDte] = useState('');
  const [wochenplan, setWochenplan] = useState(false);
  const [isoWeek, setIsoWeek] = useState(DateTime.utc(dt.year, dt.month, dt.day).toISOWeekDate());
  const [menueSetup, setMenueSetup] = useState(false);
  const [menueData, setMenueData] = useState(false);
  const [KalenderEssen, setKalenderEssen] = useState(false);
  const [triggerPlus, setTriggerPlus] = useState(false);
  const [triggerFetchMenues, setTriggerFetchMenues] = useState(false);
  const [triggerMinus, setTriggerMinus] = useState(false);

  let fetch_getMenues = async () => {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    var raw = JSON.stringify({ clientid: credentials.clientid, kalenderwoche: isoWeek });
    console.log('RAW', raw);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    await fetch(
      process.env.NODE_ENV === 'production'
        ? `https://fb-backend.saint-online.de/admin/menuelinien/`
        : `http://localhost:3000/`,
      requestOptions
    )
      .then(response => response.json())
      .then(result => setMenueData(result))
      .catch(error => console.log('error', error));

    // console.log('THIS WEEK : ', DateTime.utc(dt.year, dt.month, dt.day).toISOWeekDate());

    setTriggerFetchMenues(false);
  };

  function dateIsoToGerman(isodate) {
    let g = isodate.split('-');
    let germandate = g[2] + '.' + g[1] + '.' + g[0];
    return germandate;
  }

  function dateIsoWeek(isodate) {
    let g = isodate.split('-');
    // console.log("ISO", g);
    let iso = DateTime.fromObject({
      year: g[0],
      month: g[1],
      day: g[2],
    }).toISOWeekDate();
    // console.log("ISO2", iso);
    return iso;
  }

  useEffect(() => {
    console.log(credentials);
  }, [credentials]);

  useEffect(() => {
    fetch_getMenues();
  }, [triggerFetchMenues]);

  useEffect(() => {
    if (isoWeek) console.log('STATE', isoWeek);
  }, [isoWeek]);

  useEffect(() => {
    let array = [];
    // console.log(array);

    for (let i = 0; i < 7; i++) {
      let start = dt.minus({ days: dt.weekday - 1 });

      let newDate = start.plus({ days: i });

      array.push(newDate.year + '-' + newDate.month + '-' + newDate.day);
    }

    setDte(array);

    let setup = ['Vollkost', 'Diät', 'Dessert'];
    setMenueSetup(setup);
    setTriggerFetchMenues(true);
  }, []);

  useEffect(() => {
    console.log(isoWeek);
    console.log(menueData);

    if (menueData.length > 0) {
      let tempEssen = [];

      menueData[0].menuelinie.map(essensArt => {
        essensArt.essen.map(gericht => {
          tempEssen.push({ name: `${gericht.datum}-${essensArt.menue}`, inhalt: gericht.inhalt });
        });
        setKalenderEssen(tempEssen);
        console.log('KAL ESSEN', tempEssen);
      });
    }
  }, [menueData]);

  useEffect(() => {
    if (triggerPlus && dte) {
      let g = dte[0].split('-');
      let props = DateTime.utc(Number(g[0]), Number(g[1]), Number(g[2]));

      let array = [];
      let start = props.plus({ weeks: 1 });

      for (let i = 0; i < 7; i++) {
        let newDate = start.plus({ days: i });
        array.push(newDate.year + '-' + newDate.month + '-' + newDate.day);
      }

      setDte(array);
      setTriggerFetchMenues(true);
      setTriggerPlus(false);
    }
  }, [dte, triggerPlus]);

  useEffect(() => {
    if (triggerMinus && dte) {
      let g = dte[0].split('-');
      let props = DateTime.utc(Number(g[0]), Number(g[1]), Number(g[2]));

      let array = [];
      let start = props.minus({ weeks: 1 });

      for (let i = 0; i < 7; i++) {
        let newDate = start.plus({ days: i });
        array.push(newDate.year + '-' + newDate.month + '-' + newDate.day);
      }
      setDte(array);
      setTriggerFetchMenues(true);
      setTriggerMinus(false);
    }
  }, [dte, triggerMinus]);

  useEffect(() => {
    // console.log();
  }, [dte, menueSetup]);

  const onClick_Plus = () => {
    let g = dte[0].split('-');
    let props = DateTime.utc(Number(g[0]), Number(g[1]), Number(g[2]));
    let start = props.plus({ weeks: 1 }).toISOWeekDate();

    setIsoWeek(start);
    setTriggerPlus(true);
  };

  const onClick_Minus = () => {
    let g = dte[0].split('-');
    let props = DateTime.utc(Number(g[0]), Number(g[1]), Number(g[2]));
    let start = props.minus({ weeks: 1 }).toISOWeekDate();

    setIsoWeek(start);
    setTriggerMinus(true);
  };

  const zeigwas = element => {
    if (KalenderEssen) {
      let x = KalenderEssen.filter(e => e.name === element);
      if (x.length > 0) {
        return x[0].inhalt;
      } else {
        return 0;
      }
    }
  };

  const onClick_Save = () => {
    if (dte && menueSetup) {
      let finalArray = [];
      let tempMenue = [];
      menueSetup.map(e => {
        let tempEssen = [];
        dte.map(el => {
          tempEssen.push({
            datum: el,
            inhalt: document.getElementById(`${el}-${e}`).value,
          });
        });

        tempMenue.push({ menue: e, essen: tempEssen });
      });
      finalArray = {
        kalenderwoche: dateIsoWeek(dte[0]),
        clientid: credentials.clientid,
        menuelinie: tempMenue,
      };
      console.log('TEMP', finalArray);

      var myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');

      var raw = JSON.stringify(finalArray);

      console.log(raw);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      };

      fetch(
        process.env.NODE_ENV === 'production'
          ? `https://fb-backend.saint-online.de/admin/speiseplan/`
          : `http://localhost:3000/`,
        requestOptions
      )
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Menu Creator</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Menu Creatoer</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid>
          <IonRow>
            <IonCol>
              <button onClick={() => onClick_Minus()}>MINUS 1 WOCHE</button>
              <div className="kalender">
                {/* start: {start.day}.{start.month}.{start.year}<br/> */}
                {/* end: {end.day}.{end.month}.{end.year} */}
                {dte &&
                  dte.map((e, i) => (
                    <div key={i} className="kalender_datum">
                      {dateIsoToGerman(e)}
                    </div>
                  ))}
              </div>
              <div className="kalender">
                {menueSetup &&
                  KalenderEssen &&
                  dte.map((e, i) => (
                    <div key={i} id={e} className="kalender_feld">
                      {menueSetup.map(el => (
                        <>
                          <div key={el}>{el}</div>
                          <div>
                            <textarea
                              key={`${e}-${el}`}
                              id={`${e}-${el}`}
                              value={zeigwas(`${e}-${el}`)}
                              placeholder={`${e}-${el}`}
                            />
                          </div>
                        </>
                      ))}
                    </div>
                  ))}
              </div>
              <button onClick={() => onClick_Plus()}>PLUS 1 WOCHE</button>
              <button onClick={() => onClick_Save()}>SPEICHERN</button>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default MenuAdmin;
