import { IonButton, IonInput } from '@ionic/react';
import React, { useState } from 'react';

import '../app.css';

const NewInput = ({
  currentSurveyId,
  trigger,
  setNewInput,
  placeholdertext,
  setTriggerGet,
  currentPageid,
  setSurveyListChecked,
  currentElementId,
  credentials,
  inputlimitation,
}) => {
  const [inputValue, setInputValue] = useState(null);

  const newInputHandler = async (inputvalue, inputlimitation) => {
    try {
      if (inputlimitation === 'nospace') {
        const invalid = /\s/;
        const test = invalid.test(inputvalue);
        if (test) {
          alert('no spaces allowed');
          return;
        }
      }

      const initfetch = await fetch(
        process.env.NODE_ENV === 'production'
          ? `https://fb-backend.saint-online.de/editsurvey/`
          : `http://localhost:3000/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            clientid: credentials,
            trigger,
            ...(currentSurveyId && { surveyid: currentSurveyId }),
            ...(currentPageid && { pageid: currentPageid }),
            ...(currentElementId && { elementid: currentElementId }),
            ...(inputvalue && { input: inputvalue }),
            ...(trigger === 'newsurvey' && { input: inputvalue }),
            ...(trigger === 'newquestion' && { input: inputvalue }),
            ...(trigger === 'newpage' && { input: inputvalue }),
            ...(trigger === 'renamequestion' && { input: inputvalue }),
          }),
        }
      );

      const data = await initfetch.json();

      console.log(data);
      setNewInput(false);
    } catch (err) {
      console.log(err);
    } finally {
      if (trigger === 'newsurvey') {
        setSurveyListChecked({ _id: null });
      }
      setTriggerGet(true);
    }
  };

  // let textInput = React.createRef();

  // useEffect(() => console.log(textInput), [textInput]);

  return (
    <>
      <IonInput
        type="text"
        // ref={textInput}
        placeholder={placeholdertext}
        value={inputValue}
        onIonChange={e => setInputValue(e.detail.value)}></IonInput>

      <IonButton
        shape="round"
        fill="outline"
        size="small"
        onClick={() => newInputHandler(inputValue, inputlimitation)}
        color="primary">
        Speichern
      </IonButton>
      <IonButton
        shape="round"
        fill="outline"
        size="small"
        onClick={() => setNewInput(false)}
        color="primary">
        Löschen
      </IonButton>
    </>
  );
};

export default NewInput;
